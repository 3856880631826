<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">상담고객관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2" @click="getListData" id="btnSearch">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  상담고객목록
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="240px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                      
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-autocomplete
                        :items="ctcpTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        dense
                        @change="changeDetailBussType(S_CTCP_CODE)"
                        v-model="S_CTCP_CODE"
                        hide-details
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                        :items="bussTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        no-resize
                        v-model="S_BUSS_CODE"
                        @change="changeDetailCmpType(S_BUSS_CODE)"
                        :disabled="bussTypeCd.length == 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr> 
                  <tr>
                    <th scope="row">캠페인명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                        :items="cmpTypeCd"
                        item-text="CMP_NAME"
                        item-value="CMP_ID"
                        outlined
                        v-model="S_CMP_CODE"
                        :disabled="cmpTypeCd.length == 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row" >
                      상담상태
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CNSL_STAT_CD', '전체')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        v-model="S_CNSL_STAT_CODE"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                
                    <th scope="row" >
                      전화번호
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-text-field
                        class="form-inp"
                        outlined
                        hide-details
                        v-model="S_USER_PHONE"
                        v-on:keyup.enter="getListData"
                        >
                        </v-text-field>
                      </div>
                    </td>
                    <th scope="row" >
                      이름
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-text-field
                          class="form-inp full"
                          outlined
                          hide-details
                          @keyup.enter="getListData"
                          v-model="S_CUST_NAME"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  
                  </tr>
            
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">기간 조회 타입</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                        :items="dateSearchTypeArr"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        @change="fnSdateSType"
                        v-model="S_DATE_SEARCH_TYPE"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">{{ DATE_TEXT }} 기간</v-badge>
                    </th>
                    <td>
                      <v-menu 
                        v-model="menu1" 
                        :close-on-content-click="false" 
                        :nudge-right="40"
                        transition="scale-transition" 
                        offset-y 
                        min-width="auto" 
                        content-class="calendar-modal"
                        color="#FFF"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          class="form-inp icon-calendar full"
                          append-icon="svg-calendar"
                        ></v-text-field>
                      </template>
                      <div class="datepicker-range">
                        <v-date-picker
                          v-model="dates[0]"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="startDate"
                        ></v-date-picker>
                        <v-date-picker
                          v-model="dates[1]"
                          color="#F4B228"
                          no-title
                          show-current="false"
                          :min="dates[0]"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="endDate"
                        ></v-date-picker>
                      </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn
                          outlined
                          small
                          class="btn-point ml-3"
                          @click="menu1 = false"
                          >확인</v-btn
                        >
                      </div>
                    </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">상담고객 목록
              <div class="ml-auto">
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcel('기간별 상담고객 현황')"
              >엑셀다운로드</v-btn>
              </div>
            </h1>
            
            <div class="mt-2">
              <v-data-table
              dense
              height="450px"
              :headers="gridDataHeaders"
              :items="gridDataText"
              :items-per-page="itemsPerPage"
              item-key="IDX"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              fixed-header
              id="mainTable"
              @page-count="pageCount = $event"
              @click:row="gridRowClick"
              v-model="selGroupCd"
              :item-class="clickActive"
              no-data-text="검색된 결과가 없습니다."
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>
        <!-- 알림톡 상세내용 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">상담고객 상세내용

            <div class="ml-auto">
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcelDetailInfo('기간별 상담고객 상세내용')"
              >엑셀다운로드</v-btn>
              <v-btn outlined small class="btn-default ml-2" type="button"  @click="clickNew" :disabled="newBtn">신규</v-btn>
              <v-btn outlined small class="btn-point ml-2" type="button"  @click="newBtnSave" :disabled="saveBtn">저장</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  상담고객 정보 항목
                </caption>
                <colgroup>
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">건설사명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CTCP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">사업지명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_BUSS_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">캠페인명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CMP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">고객명
                    </th>
                    <td style="width:100%">
                      <div class="form-inp full">
                        <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        v-model="T_USER_NAME"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">전화번호
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :readonly="disabledCustPhone"
                        v-model="T_USER_PHONE"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <!-- -------------------------------- -->
                  <tr>
                    <th scope="row">연령
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_AGE_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        v-model="T_USER_AGE"
                        >	
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">청약유형
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CUST_SUBS_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        v-model="T_USER_SUBS"
                        >	
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">선호평형
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CUST_AREA_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        v-model="T_USER_AREA"
                        >	
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">구입목적
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CUST_REASON_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        v-model="T_USER_REASON"
                        >	
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">주소
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        v-model="T_USER_ADDRESS"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <!-- -------------------------------- -->

                  <tr>
                    <th scope="row">등록일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_REG_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">예약일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_RSVT_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">개인정보수집동의</th>
                    <td>
                      <div class="form-inp full" >
                        <v-select
                          :items="['Y','N']"
                          outlined
                          :disabled="disabledPrsnArge"
                          v-model="T_PRSN_AGRE_YN"
                          ></v-select>
                      </div>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >마케팅활용동의</th>
                    <td>
                      <div class="form-inp full" >
                        <v-select
                          :items="['Y','N']"
                          outlined
                          :disabled="disabledMktgAgre"
                          v-model="T_MKTG_AGRE_YN"
                          ></v-select>
                      </div>
                    </td> 
                  </tr>

                  <tr>
                    <th scope="row" >상담사ID</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :readonly="disabledCnslrId"
                        v-model="T_CNSLR_ID"
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >상담상태</th>
                    <td >
                      <div class="form-inp full" >
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CNSL_STAT_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        :disabled="disabledCnslStatCd"
                        v-model="T_CNSL_STAT_CD"
                        >
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    <th scope="row" >고객성향코드</th>
                    <td >
                      <div class="form-inp full" >
                        <v-select
                        :items="mixin_common_code_get(this.allCodeList, 'HLW_CUST_PPST_CD', '')"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        :disabled="disabledCustPpstCd"
                        v-model="T_CUST_PPST_CD"
                        >	
                        </v-select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" >대기번호</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CNSL_WAIT_NUM"
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >상담내용</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        height="150px"
                        counter
                        no-resize
                        outlined
                        
                        v-model="T_CNSL_QUST_CONT"
           
                      >
                      </v-textarea>
                    </td> 
                
                  </tr>
                  
                  


                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CUM0200", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        menu1:false,
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        ],
        dateSearchTypeArr:[
          {
            CD:''
            , CD_NM:'등록&예약'
          },
          {
            CD:'REG'
            , CD_NM:'등록일'
          },
          {
            CD:'RES'
            , CD_NM:'예약일'
          },
        ],
        bussTypeCd:[],
        ctcpTypeCd:[],
        cmpTypeCd:[],
        bussTypeCdT:[],
        ctcpTypeCdT:[],
        CmdCdT:[],
        selGroupCd:[],
        gridDataText: [],
        disabledCustPhone: true,
        disabledCustPpstCd: true,
        disabledCnslStatCd: true,
        disabledPrsnArge: true,
        disabledMktgAgre: true,
        disabledCnslrId: true,
        newBtn: false,
        saveBtn: true,
        newYn : false,

        S_CTCP_CODE: "",
        S_BUSS_CODE: "",
        S_CMP_CODE: "",
        S_USER_PHONE: "",
        S_CNSL_STAT_CODE: "",

        ROW_NUMBER:"",
        T_CTCP_NAME: "",
        T_BUSS_NAME: "",
        T_CMP_NAME: "",
        T_USER_NAME: "",
        T_USER_PHONE: "",
        T_REG_DT: "",
        T_RSVT_DT: "",
        T_CNSLR_ID: "",
        T_PRSN_AGRE_YN: "",
        T_MKTG_AGRE_YN: "",
        T_CNSL_STAT_CD: "",
        T_CUST_PPST_CD: "",
        T_CNSL_QUST_CONT: "",
        T_CNSL_WAIT_NUM : "",
        H_CUST_CNSL_ID: "",
        USER_AUTH_CHK:"",

        // 2024. 04. 23 필드 추가 대응 kjh
        T_USER_AGE: "",
        T_USER_SUBS: "",
        T_USER_AREA: "",
        T_USER_REASON: "",
        T_USER_ADDRESS: "",
        
        allCodeList: "",
        EXCEL_CMP_NAME:"",
        EXCEL_USER_NAME:"",
        custPpstCd:[],
        gridDataTextInfo:[],
        S_CUST_NAME:'',
        S_DATE_SEARCH_TYPE:'',
        DATE_TEXT:'',

        gridDataHeaders: [
          { text: "No",              value: "IDX",          align: "center", width: "50px", sortable: true  },
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "160px", sortable: true },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE", align: "center", width: "100px", sortable: true },
          { text: "상담상태",      value: "CNSL_STAT_NAME",        align: "center", width: "100px", sortable: true },

          { text: "건설사명",         value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지명",         value: "BUSS_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "등록일시",           value: "REG_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "예약일시",           value: "CNSL_RSVT_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "상담상태",        value: "CNSL_STAT_CD",       width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "고객성향코드",      value: "CUST_PPST_CD",       width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "고객성향명",      value: "CUST_PPST_NAME",       width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "상담내용",      value: "CNSL_QUST_CONT",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "상담사ID",      value: "CNSLR_ID",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "고객상담ID",      value: "CUST_CNSL_ID",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },

          { text: "연령",        value: "CUST_AGE",              width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "청약유형",     value: "SUBS_CD",               width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "선호평형",     value: "AREA_CD",               width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "구입목적",     value: "REASON_CD",             width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "주소",     value: "CUST_ADDRESS",          width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "상담내용",      value: "CNSL_QUST_CONT",        width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false }
        ],

        excelDataHeaders: [
          { text: "건설사명",         value: "CTCP_NAME",       align: "center", width: "200px"},
          { text: "사업지명",         value: "BUSS_NAME",       align: "center", width: "300px"},
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "300px" },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "120px" },
          { text: "전화번호",         value: "CUST_PHONE",        align: "center", width: "280px"},
          { text: "등록일시",           value: "REG_DT",           align: "center", width: "400px" },
          { text: "예약일시",           value: "CNSL_RSVT_DT",     align: "center", width: "400px" },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",    align: "center", width: "120px" },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",      align: "center", width: "120px"},
          { text: "상담사ID",      value: "CNSLR_ID",             align: "center", width: "100px"},
          { text: "상담상태",      value: "CNSL_STAT_NAME",        align: "center", width: "100px"},
          { text: "고객성향",      value: "CUST_PPST_NAME",        align: "center", width: "100px"},
          { text: "연령",        value: "CUST_AGE",              align: "center", width: "100px"},
          { text: "청약유형",     value: "SUBS_CD",               align: "center", width: "100px"},
          { text: "선호평형",     value: "AREA_CD",               align: "center", width: "100px"},
          { text: "구입목적",     value: "REASON_CD",             align: "center", width: "100px"},
          { text: "주소",     value: "CUST_ADDRESS",          align: "center", width: "100px"},
          { text: "상담내용",      value: "CNSL_QUST_CONT",        align: "center", width: "100px"},
        ],

        page: 1,
        pageCount: 0,
        itemsPerPage: 30,
        totalVisible: 10,

        ageRadioGroup: {
            "AGE_CD01": "20대",
            "AGE_CD02": "30대",
            "AGE_CD03": "40대",
            "AGE_CD04": "50대",
            "AGE_CD05": "60대 이상",
        },

        subsRadioGroup: {
          "CUST_SUBS_CD_01": "특별공급",
          "CUST_SUBS_CD_02": "1순위",
          "CUST_SUBS_CD_03": "2순위",
          "CUST_SUBS_CD_04": "선착순",
          "CUST_SUBS_CD_05": "기타",
        },

        areaRadioGroup: {
          "CUST_AREA_CD_01": "60㎡ 이하",
          "CUST_AREA_CD_02": "80㎡ 이하",
          "CUST_AREA_CD_03": "85㎡ 이하",
          "CUST_AREA_CD_04": "85㎡ 초과",
          "CUST_AREA_CD_05": "기타",
        },
        
        reasonRadioGroup: {
          "CUST_REASON_CD_01": "실거주",
          "CUST_REASON_CD_02": "투자",
          "CUST_REASON_CD_03": "기타",
        }
      };
    },

    methods: {

      fnSdateSType() {
        if(this.S_DATE_SEARCH_TYPE=='REG') {
          this.DATE_TEXT='등록일';
        }else if(this.S_DATE_SEARCH_TYPE=='RES') {
          this.DATE_TEXT='예약일';
        }else{
          this.DATE_TEXT='';
        }
      },

      startDate(e) {
        this.dates[0] = e;
      },

      endDate(e) {
        this.dates[1] = e;
      },

      activeRow(row, value) {
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRow(row, value) {
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      //**********************************
      //      상담고객 조회 호출
      //**********************************
      async getListData(){

        if (this.USER_AUTH_CHK ==  "MANAGER"){
          if(this.S_BUSS_CODE == undefined ||this.S_BUSS_CODE == ""){
            this.common_alert('사업지명을 선택해주세요.', 'chk');
            return;  
          }  
        }

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-cnslrRegist/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-cnslrRegist.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CMP_ID"]          = this.S_CMP_CODE;
        requestData.sendData["CTCP_CODE"]       = this.S_CTCP_CODE;        //건설사코드
        requestData.sendData["BUSS_CODE"]       = this.S_BUSS_CODE;        //사업지코드
        requestData.sendData["CUST_PHONE"]      = this.S_USER_PHONE;
        requestData.sendData["CUST_NAME"]      = this.S_CUST_NAME;
        requestData.sendData["CNSL_STAT_CD"]    = this.S_CNSL_STAT_CODE;    
        requestData.sendData["START_DT"]        = this.dates[0].replaceAll("-", "");
        requestData.sendData["END_DT"]          = this.dates[1].replaceAll("-", "");
        requestData.sendData["DATE_SEARCH_TYPE"] = this.S_DATE_SEARCH_TYPE

        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG){
          this.result(response);
          this.cleanValidation();   //오른쪽 내용 초기화
        }else{
          this.common_alert("관리자에게 문의해주세요.","error");
        }
      },  

      newBtnSave() {
        if(this.H_CUST_CNSL_ID == "" && !this.newYn) {
          this.common_alert('상담고객을 선택해주세요.', 'chk');
          return;  
        }  

        this.common_confirm("내용을 저장하시겠습니까?", !this.newYn?this.newCnslSave:this.newCnslSave1, null, null, null, 'chk');

      },

      findNameByCD(jsonArray,cdValue){
        var foundObject = jsonArray.find(item => item.CD === cdValue);

        if(!foundObject) foundObject = jsonArray.find(item => item.CMP_ID === cdValue);

        return foundObject.CD_NM ? foundObject.CD_NM : foundObject.CMP_NAME;
      },

      clickNew() {
        if(this.mixin_isEmpty(this.S_BUSS_CODE)) {
          this.common_alert('사업지를 선택해주세요.', 'chk');
          return;  
        }  
        if(this.mixin_isEmpty(this.S_CMP_CODE)) {
          this.common_alert('캠페인을 선택해주세요.', 'chk');
          return;  
        } 

        this.cleanValidation();
        this.setNewCnslData();

        this.disabledCustPpstCd= false;
        this.disabledCnslStatCd= false;
        this.disabledCustPhone=false;
        this.disabledPrsnArge=false;
        this.disabledMktgAgre=false;
        this.disabledCnslrId=false;

        this.newYn = true;
        this.saveBtn = false;      
      },

      //**********************************
      //      상담고객 저장 호출 (신규)
      //**********************************
      async newCnslSave1(){
        if(this.mixin_isEmpty(this.T_USER_PHONE)) {
          this.common_alert('전화번호를 입력해주세요.', 'error');
          return;  
        }                           

        let requestData = {
          headers:{},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-cnslrRegist/manage/registNew";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-cnslrRegist.manage";
        requestData.headers["METHOD"] = "registNew";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BUSS_CODE"]         = this.S_BUSS_CODE;  
        requestData.sendData["CMP_ID"]            = this.S_CMP_CODE;   

        //sendData 초기화
        requestData.sendData["CNSL_STAT_CD"]      = this.T_CNSL_STAT_CD;    //상담상태
        requestData.sendData["CUST_PPST_CD"]      = this.T_CUST_PPST_CD;    //상담성향코드
        requestData.sendData["CNSL_QUST_CONT"]    = this.encode(this.T_CNSL_QUST_CONT);  //상담내용
        requestData.sendData["REG_ID"]            = this.user_id
        requestData.sendData["CUST_NAME"]         = this.T_USER_NAME            // 고객명

        requestData.sendData["CUST_PHONE"]        = this.T_USER_PHONE.replaceAll("-","")           // 전화번호
        requestData.sendData["MKTG_AGRE_YN"]      = this.T_MKTG_AGRE_YN         // 마케팅활용동의
        requestData.sendData["PRSN_AGRE_YN"]      = this.T_PRSN_AGRE_YN         // 개인정보수집동의
        requestData.sendData["CNSLR_ID"]          = this.T_CNSLR_ID             // 상담사 ID

        requestData.sendData["CUST_ADDRESS"]      = this.T_USER_ADDRESS         // 고객 주소
        requestData.sendData["CUST_AGE"]          = this.T_USER_AGE             // 연령대
        requestData.sendData["SUBS_CD"]           = this.T_USER_SUBS            // 청약 유형
        requestData.sendData["AREA_CD"]           = this.T_USER_AREA            // 선호 평형
        requestData.sendData["PURC_CD"]           = this.T_USER_REASON          // 구입 목적
        
        this.saveBtn = true; 

        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {
          this.common_alert("정상적으로 처리되었습니다.");
          this.getListData();
        }else{
          this.common_alert(response.HEADER.ERROR_MSG,"error");
        }
      },

      setNewCnslData(){
        this.T_CTCP_NAME = this.findNameByCD(this.ctcpTypeCd,this.S_CTCP_CODE);
        this.T_BUSS_NAME = this.findNameByCD(this.bussTypeCd,this.S_BUSS_CODE);
        this.T_CMP_NAME = this.findNameByCD(this.cmpTypeCd,this.S_CMP_CODE);
      },

      //**********************************
      //      상담고객 저장 호출 (수정)
      //**********************************
      async newCnslSave() {

        if(this.mixin_isEmpty(this.T_USER_PHONE)) {
          this.common_alert('고객을 선택해주세요.', 'chk');
          return;  
        }
      
        let requestData = {
          headers:{},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-cnslrRegist/manage/regist";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-cnslrRegist.manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CUST_CNSL_ID"]      = this.H_CUST_CNSL_ID;     //상담ID(KEY값)
        requestData.sendData["CNSL_STAT_CD"]      = this.T_CNSL_STAT_CD;    //상담상태
        requestData.sendData["CUST_PPST_CD"]      = this.T_CUST_PPST_CD;    //상담성향코드
        requestData.sendData["CNSL_QUST_CONT"]    = this.encode(this.T_CNSL_QUST_CONT);  //상담내용
        requestData.sendData["REGR_ID"]           = this.user_id
        requestData.sendData["CUST_NAME"]         = this.T_USER_NAME            // 고객명ㅂㅈㄷ

        requestData.sendData["CUST_ADDRESS"]      = this.T_USER_ADDRESS         // 고객 주소
        requestData.sendData["CUST_AGE"]          = this.T_USER_AGE             // 연령대
        requestData.sendData["SUBS_CD"]           = this.T_USER_SUBS            // 청약 유형
        requestData.sendData["AREA_CD"]           = this.T_USER_AREA            // 선호 평형
        requestData.sendData["PURC_CD"]           = this.T_USER_REASON          // 구입 목적
        

        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {
          this.common_alert("정상적으로 처리되었습니다.");
          this.getListData();
        }else{
          this.common_alert("관리자에게 문의해주세요.","error");
        }
      },

      /**
      * XSS Encode
      */
      encode(strText) {
        if (strText === "" || strText === null) {
          return strText;
        }
        //strText = strText.toString();

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");

        return strText;
      },      

      //엑셀다운로드(상세목록)
      downloadExcel(fileNm) {
        let fileName = this.dates[0] + '_' +this.dates[1] + '_' + this.EXCEL_CMP_NAME + '_' + fileNm;

        for(let i = 0; i < this.gridDataText.length; i++) {
          const age = this.gridDataText[i]["CUST_AGE"]
          const subs = this.gridDataText[i]["SUBS_CD"]
          const purc = this.gridDataText[i]["PURC_CD"]
          const area = this.gridDataText[i]["AREA_CD"]

          this.gridDataText[i]["CUST_AGE"] = this.ageRadioGroup[age]
          this.gridDataText[i]["SUBS_CD"] = this.subsRadioGroup[subs]
          this.gridDataText[i]["REASON_CD"] = this.reasonRadioGroup[purc]
          this.gridDataText[i]["AREA_CD"] = this.areaRadioGroup[area]
          this.gridDataText[i]["CNSL_QUST_CONT"] = this.decode(this.gridDataText[i]["CNSL_QUST_CONT"])
        }

        console.log(">>>>>>> downloadExcel")
        console.log(this.gridDataText)
        this.mixin_common_Excel(this.excelDataHeaders,this.gridDataText, fileName, this.EXCEL_CMP_NAME);
      },

      //엑셀다운로드(상세내용)
      downloadExcelDetailInfo(fileNm){

        if(!this.mixin_isEmpty(this.gridDataTextInfo)) {
          let jsonObj = JSON.parse(JSON.stringify(this.gridDataTextInfo)); //json string data를 object 형태로 파싱
          this.gridDataTextInfo = ([jsonObj]) ;

          let fileName =  this.EXCEL_USER_NAME + '_' + fileNm;

          const age = this.gridDataTextInfo[0]["CUST_AGE"]
          const subs = this.gridDataTextInfo[0]["SUBS_CD"]
          const purc = this.gridDataTextInfo[0]["PURC_CD"]
          const area = this.gridDataTextInfo[0]["AREA_CD"]

          this.gridDataTextInfo[0]["CUST_AGE"] = this.ageRadioGroup[age]
          this.gridDataTextInfo[0]["SUBS_CD"] = this.subsRadioGroup[subs]
          this.gridDataTextInfo[0]["REASON_CD"] = this.reasonRadioGroup[purc]
          this.gridDataTextInfo[0]["AREA_CD"] = this.areaRadioGroup[area]
          this.gridDataTextInfo[0]["CNSL_QUST_CONT"] = this.decode(this.gridDataTextInfo[0]["CNSL_QUST_CONT"])

          console.log(">>>>>>> downloadExcel")
          console.log(this.gridDataTextInfo)
        
          this.mixin_common_Excel(this.excelDataHeaders,this.gridDataTextInfo, fileName, this.EXCEL_USER_NAME);
          this.gridDataTextInfo = [];
        } else {
          this.common_alert('목록 데이터를 클릭후 다운로드 받아주세요.', 'chk');
          return;
        }
      },
          
      //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {

        this.bussTypeCd = [];
        this.bussTypeCd.push({ CD: "", CD_NM: "" });
        this.cmpTypeCd = [];
        this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });
        this.S_CMP_CODE = "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          if (this.USER_AUTH_CHK ==  "SYSTEMMANAGER"){
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else{
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.user_id);
          }

          if(this.bussTypeCd.length === 0){
            this.S_BUSS_CODE = "";
            this.S_CMP_CODE = "";
          }
    
        }else{
          this.S_BUSS_CODE =  "";
        }

      },

      //캠페인 코드 가져오기
      async changeDetailCmpType(bussTypeCd) {
        this.cmpTypeCd = [];
        this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });

        if (!this.mixin_isEmpty(bussTypeCd)) {
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd); 
          if(this.cmpTypeCd.length === 0){
            this.S_CMP_CODE = "";
          }
        }else{
          this.S_CMP_CODE = "";
        }
      },

      cleanValidation() {
        this.ROW_NUMBER       = "";
        this.T_CTCP_NAME      = "";
        this.T_BUSS_NAME      = "";
        this.T_CMP_NAME       = "";
        this.T_USER_NAME      = "";
        this.T_USER_PHONE     = "";
        this.T_REG_DT         = "";
        this.T_RSVT_DT        = "";
        this.T_PRSN_AGRE_YN   = "";
        this.T_MKTG_AGRE_YN   = "";
        this.T_CNSL_STAT_CD   = "";
        this.T_CUST_PPST_CD   = "";
        this.T_CNSLR_ID       = "";
        this.T_CNSL_QUST_CONT = "";
        this.T_CNSL_WAIT_NUM  = "";
        this.H_CUST_CNSL_ID   = "";

        // 2024. 04. 23 필드 추가 대응 kjh
        this.T_USER_ADDRESS   = "";
        this.T_USER_AGE       = "";
        this.T_USER_AREA      = "";
        this.T_USER_REASON    = "";
        this.T_USER_SUBS      = "";

        this.disabledCustPhone = true;
        this.disabledCustPpstCd= true;
        this.disabledCnslStatCd= true;
        this.disabledPrsnArge= true;
        this.disabledMktgAgre= true;
        this.disabledCnslrId = true;

        this.saveBtn = true;      // 저장버튼
      },

      /**
      * XSS Decode
      */
      decode(strText) {
        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }
        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br/>", "\r");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },

      gridRowClick(item, row) {
        this.gridDataTextInfo = "";

        if (item != null) {
          if (row.isSelected == false) {
            this.activeRow(row, true);
          } else {
            this.disableRow(row, false);
          }
        }

        console.log(item)
        console.log(row)

        this.ROW_NUMBER       = item.IDX;
        this.T_CTCP_NAME      = item.CTCP_NAME;
        this.T_BUSS_NAME      = item.BUSS_NAME;
        this.T_CMP_NAME       = item.CMP_NAME;
        this.T_USER_NAME      = item.CUST_NAME;
        this.T_USER_PHONE     = item.CUST_PHONE;
        this.T_REG_DT         = item.REG_DT;
        this.T_RSVT_DT        = item.CNSL_RSVT_DT;
        this.T_PRSN_AGRE_YN   = item.PRSN_AGRE_YN;
        this.T_MKTG_AGRE_YN   = item.MKTG_AGRE_YN;
        this.T_CNSL_STAT_CD   = item.CNSL_STAT_CD;
        this.T_CUST_PPST_CD   = item.CUST_PPST_CD;
        this.T_CNSLR_ID       = item.CNSLR_ID;
        this.T_CNSL_WAIT_NUM  = item.CNSL_WAIT_NUM;
        this.T_CNSL_QUST_CONT = this.decode(item.CNSL_QUST_CONT);
        this.H_CUST_CNSL_ID   = item.CUST_CNSL_ID;//상담 ID(저장할때 where조건에 들어가기위함)

        // 2024. 04. 23 필드 추가 대응 kjh
        this.T_USER_ADDRESS   = item.CUST_ADDRESS;
        this.T_USER_AGE       = item.CUST_AGE;
        this.T_USER_AREA      = item.AREA_CD;
        this.T_USER_REASON    = item.PURC_CD;
        this.T_USER_SUBS      = item.SUBS_CD;

        this.disabledCustPpstCd= false;
        this.disabledCnslStatCd= false;
        this.disabledCustPhone=true;
        this.disabledPrsnArge=true;
        this.disabledMktgAgre=true;      
        this.disabledCnslrId=true;  

        this.saveBtn = false;      // 저장버튼
        this.newYn = false;
        this.gridDataTextInfo = item; //엑셀 body
        this.EXCEL_USER_NAME= item.CUST_NAME;

        console.log(this);
      },

      clickActive(item){
        //클릭 시, 하이라이트
        return item.IDX == this.ROW_NUMBER ? 'active' : '';
      },

      result(res){
        let header = res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataText = data;
        this.EXCEL_CMP_NAME= "";  //이전내용 초기화
        this.EXCEL_USER_NAME= "";  //이전내용 초기화

        if (this.gridDataText.length === 0) {
          return;
        }

        this.EXCEL_CMP_NAME= this.gridDataText[0]["CMP_NAME"];
        let idx = 1;
        
        for(var i in this.gridDataText){
          this.gridDataText[i]["IDX"] = idx++;
        }
      },
    
    },

    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;

      let pArr = ['HLW_CTCP_CD', 'HLW_CNSL_STAT_CD', 'HLW_CUST_PPST_CD', 'HLW_AGE_CD', 'HLW_CUST_AREA_CD', 'HLW_CUST_REASON_CD', 'HLW_CUST_SUBS_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if (userGroupCd == "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = userGroupCd;
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
        this.cnslrStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CNSLR_STAT_CD');
      }else {
        this.USER_AUTH_CHK = "MANAGER";
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.user_id);

        if(usserAuth.length > 0 ){
          for (let i = 0; i < usserAuth.length; i++) {
            this.ctcpTypeCd.push({CD_NM: usserAuth[i].CTCP_NAME, CD: usserAuth[i].CTCP_CODE });
            this.bussTypeCd.push({ CD_NM: usserAuth[i].BUSS_NAME, CD: usserAuth[i].BUSS_CODE });  
          }
          this.cmpTypeCd = this.changeDetailCmpType(this.bussTypeCd[0].CD);
          this.S_BUSS_CODE = usserAuth[0].BUSS_CODE;
          this.S_CTCP_CODE = usserAuth[0].CTCP_CODE;
          this.changeDetailBussType(this.S_CTCP_CODE);
        }
      }
    },

    computed: {

      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },
      
      initHeaders(){
        return {
        };
      },
      paramData(){
        return {
        };
      }
    },
  };
</script>